.dates {
  display: flex;
  gap: 10px;
  margin-top: 0;
  max-width: 520px;
  margin-top: 10px;
  align-items: flex-end;

  .wrapper-dates{
    display: flex;
    gap: 10px;
    margin-top: 0;
    max-width: 520px;
    margin-top: 10px;
    align-items: center;
    flex-grow: 1;

    .TextField{
      min-width: calc((100% - 40px) / 2);
      max-width: calc((100% - 40px) / 2);

      & > div{
        max-width: 100%;
        min-width: 100%;
      }
    }

    .value-duplicator{
      max-width: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
    }
  }
  .wrapper-presets{
    width: 100px;
    flex-shrink: 0;

    .label-field{
      margin: 0px;
    }
  }
}

@media(max-width: 520px){
  .dates{
    flex-wrap: wrap;
    & > div{
      flex-basis: 100%;
    }
    .value-duplicator{
      max-width: 100%;
      min-width: 100%;
    }
  }
}