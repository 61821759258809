.audit-page {
	display: flex;
	width: 100%;

	.audit-list {
		flex-grow: 1;

		.filters {
			display: flex;
			align-items: center;
			column-gap: 20px;
			margin-top: 10px;
		}

		.audit-body {
			display: flex;
			column-gap: 25px;
			height: calc(100% - 140px);

			.audit-table {
				width: 100%;
				padding-bottom: 24px;

				.audit-table-headers {
					display: flex;
					align-items: center;
					color: #393e46;
					font-weight: 700;
					font-size: 14px;
					line-height: 16px;
					padding: 24px 42px;
					filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
					background: #ffffff;

					.audit-table-header {
						display: flex;
						cursor: pointer;
						align-items: center;
						position: relative;
						column-gap: 10px;
						left: -26px;

						.icon-sorted {
							transform: rotate(180deg);
						}
					}
				}

				.audit-table-headers,
				.audit-table-item {
					border-bottom: 1px solid rgba(#000, 0.15);

					& > div {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.date {
						flex-basis: 25%;
						flex-shrink: 0;
					}

					.description {
						flex-basis: 30%;
						flex-shrink: 0;
					}

					.owner {
						flex-basis: 25%;
					}

					.action {
						flex-basis: 20%;
						flex-shrink: 0;
					}
				}
				.audit-table-items-wrapper {
					overflow-y: hidden;
					height: calc(100vh - 335px);
					filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

					.audit-table-items {
						display: flex;
						flex-direction: column;
						background: #ffffff;

						.audit-table-item {
							height: 64px;
							display: flex;
							align-items: center;
							cursor: pointer;
							padding: 24px 42px;
							filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

							&:last-child {
								border-color: transparent;
							}

							&:hover {
								background: #e8f0f8;
							}
						}

						.active {
							background: #dbe2e8 !important;
						}
					}
				}
			}

			.audit-single {
				filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
				width: 50%;

				.card-content {
					overflow-y:auto;
					height: 100%;
				}

				svg {
					color: var(--primary);

					*[stroke] {
						stroke: var(--primary);
					}
				}

				.audit-single-body {
					overflow: auto;
					height: calc(100% - 45px);

					.audit-single-items {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 20px;

						.audit-single-item {
							display: flex;
							row-gap: 15px;
							flex-direction: column;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							label {
								font-weight: 500;
								color: #37b813;
							}
						}
					}
				}
			}
		}
	}
}
