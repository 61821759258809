.header {
	position: fixed;
	right: 0px;
	left: auto;
	top: 0px;
	width: calc(100vw - var(--sidebar-width));
	height: var(--header-height);
	z-index: 2;
	color: var(--slate-800);
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px 0px 24px;
	background-color: var(--slate-400);

	&-left {
		font-size: calc((16 / var(--base-font-size-no-px) * 1rem));
	}

	&-right {
		display: flex;
		gap: 20px;
	}

	.mobile-menu {
		display: none;
	}
}

@media (max-width: 1600px) {
	.header {
		width: 100%;

		.mobile-menu {
			display: block;
			flex-basis: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 1.143rem;
			text-align: center;
			cursor: pointer;
		}

		.title-mobile {
			display: block;
		}
	}
}
