.sp-dropdown.sp-dropdown-trigger {

	font-size: 1rem;

	.filter {
		height: 100%;

		.filter-content {
			padding: 0px 10px;
		}
	}

	&.sp-dropdown-list {
		.sp-dropdown-items {
			background: white;
		}
	}
}