.sp-btn.sp-btn-default {
	&.delete-btn {
		&.with-icon {
			gap: 20px;

			svg {
				*[fill] {
					fill: var(--danger) !important;
				}
			}

			span {
				color: var(--danger) !important;
			}
		}

		&:hover {
			border-color: var(--danger);

			svg {
				*[fill] {
					fill: var(--danger) !important;
				}
			}

			span {
				color: var(--danger) !important;
			}
		}
	}
}

.add-row {
	padding: 10px 0;
	width: 100%;
	text-align: center;
	cursor: pointer;

	&:hover {
		.add-btn {
			transform: scale(1.1);
		}
	}

	.add-btn {
		border-radius: 50%;
		filter: drop-shadow(0 2px 3px rgba(#000, 0.15));

		transition: all var(--transition-timing);
	}

	&.disabled {
		pointer-events: none;
		background-color: var(--content-bg);

		svg {
			filter: saturate(0);
		}
	}
}
