.restore {
	color: var(--text);
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&.disabled {
		pointer-events: none;
		color: var(--text-lighter);
	}
}
