.authentication-setting {
	max-width: 400px;

	.wrapper-slide-input {
		display: flex;
		gap: 10px;
		align-items: center;

		.sp-slide-input {
			height: max-content;
			flex-grow: 1;
			padding: 0px 10px;

			.sp-range-line {
				background: var(--primary);
			}
		}
		.number-input {
			max-width: 70px;
		}
	}

	.wrapper-group-checkbox {
		display: flex;
		flex-direction: column;
	}

	.item-setting-switch {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
		gap: 20px;

		span {
			min-width: 300px;
			max-width: 300px;
		}
	}

	.invalid-message {
		margin-top: 5px;
		font-size: 11px;
		color: var(--error);
	}

	.head-group {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.form-field {
		white-space: normal;
		word-break: normal;
	}
}

@media (max-width: 480px) {
	.authentication-setting {
		max-width: 100%;

		.item-setting-switch {
			span {
				min-width: initial;
				max-width: initial;
				flex-grow: 1;
			}
		}
	}
}
