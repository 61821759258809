.label-popup {
	text-align: center;
}

.label,
.color {
	display: inline-block;
	vertical-align: baseline;
	margin-right: 3px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #ccc;
	border: var(--border-width) solid rgba(#000, 0.05);
}

.labels-edit {
	.label-item {
		display: inline-block;
		vertical-align: top;
		margin-right: 5px;
		margin-bottom: 5px;
		height: 22px;
		line-height: 22px;
		padding: 0 10px;
		border-radius: 15px;
		font-size: 12px;
		cursor: default;
		user-select: none;

		.label-remove {
			margin-left: 10px;
			cursor: pointer;
		}
	}

	.label-add {
		display: inline-block;
		vertical-align: top;
		width: 22px;
		height: 22px;
		line-height: 22px;
		font-size: 10px;
		border-radius: 50%;
		background: var(--secondary);
		color: #fff;
		text-align: center;

		&:hover {
			background: #888;
		}
	}

	.drop-down-sm {
		max-width: 300px;
		//margin-top: 5px;
	}
}

.box-label-full {
	display: inline-block;
	vertical-align: middle;
	padding: 0 12px;
	line-height: 22px;
	margin-right: 8px;
	margin-bottom: 5px;
	border-radius: 5px;
	font-size: 12px;

	.close-btn {
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		width: 18px;
		height: 18px;
		cursor: pointer;
		transition: all var(--transition-timing);

		&:hover {
			transform: scale(1.1, 1.1);
			filter: brightness(1.2) drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.1));
		}
	}
}
