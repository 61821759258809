.report-toolbar {
  .actions {
    display: flex;
    width: 700px;
    gap: 20px;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .engine-button-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    .eng1button {
      background-color: #5470c6;
      border-radius: 50%;
      width: 15px;
      height: 15px;
    }
    .eng2button {
      background-color: #95ce7a;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-left: 15px;
    }
    .engText {
      font-size: 10px;
      margin-left: 5px;

      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .active-engine-btn {
    opacity: 0.4;
    filter: grayscale(0.8);
  }
}

@media(max-width: 700px){
  .report-toolbar {
    .actions {
      display: flex;
      width: 100%;
      gap: 20px;
      flex-wrap: wrap;
      button{
        min-width: max-content;
      }
    }
  }
}