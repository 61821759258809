.transport-module-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    #table {
        height: unset;
        flex-grow: 1;
    }
}