.wrapper-main-settings{
  .wrapper-slide-input {
    display: flex;
    gap: 10px;
    align-items: center;

    .sp-slide-input {
      height: max-content;
      flex-grow: 1;
      padding: 0px 10px;

      .sp-range-line {
        background: var(--primary);
      }
    }
    .number-input {
      max-width: 70px;
    }
  }
}