@import 'reset.css';
@import 'hover.css';
@import 'themes';
@import 'common';
@import 'ui/index';
@import 'consta/index';

$error: #d00;

html,
body,
#root {
	height: 100vh;
	font-size: 14px;
	overflow: hidden;
}

:root {
	--base-font-size: 14px;
	--base-font-size-no-px: 14;

	--transition-timing: 150ms;
	--header-height: 50px;

	--sidebar-width: 250px;
	--sidebar-left-padding: 20px;

	--toolbar-height-no-px: 36;
	--toolbar-height: 36px;

	--border-width: 1.6px;
	--border-radius: 4px;

	--toolbar-gap-row: 10px;
	--toolbar-gap-column: 10px;

	--error: #{$error};
}

body {
	font-family: 'Roboto', sans-serif;
	line-height: 1.4em;
	transition: color var(--transition-timing);
}

h1 {
	font-size: 1.2rem;
}

@media (max-width: 991px) {
	.page-title {
		display: none;
	}
}

@media print {
	@page {
		size: landscape;
		margin-top: 0;
	}

	html,
	body,
	#root {
		overflow: initial;
		height: unset !important;
	}

	.string-value {
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		max-height: 20px;
		width: 60px;
	}

	.table-report {
		display: flex;
		flex-direction: column;
		gap: 20px;
		overflow: initial;
	}

	.header,
	.sp-tabs-container,
	.report-toolbar {
		display: none;
	}

	.button-group {
		.sp-btn.sp-btn-default {
			display: none;
		}
	}

	.page {
		overflow: initial !important;
		height: unset !important;
	}
}

.warn-message {
	background: var(--warn-message-bg);
	padding: 10px;
	border: 1px dashed rgba(0, 0, 0, 0.2);
	width: auto;
	display: inline-block;
}
