.avatar-fio {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;

	.fio {
		display: inline-block;
		width: calc(100% - 34px);
		vertical-align: middle;
		margin-left: 10px;
	}
}

.box-contragent {
	display: inline-flex;
	align-items: center;
	gap: 10px;
	background-color: var(--section-header-color);
	border-radius: var(--border-radius);
	padding: 2px 10px;

	.avatar-fio {
		display: flex;
		align-items: center;

		.fio {
			overflow: unset;
			text-overflow: unset;
			min-width: 10px;
			line-height: normal;
		}
	}
}

.user-card {
	width: 400px;
	max-width: 100%;
	background: var(--main-bg);
	padding: 5px;
	border: var(--border-width) solid var(--border-color);
	border-radius: var(--border-radius);
	font-size: 0.928rem;
	margin-bottom: 10px;

	.name {
		margin-bottom: 2px;

		.actions {
			float: right;

			.edit-btn {
				cursor: pointer;
			}
		}
	}

	.info {
		display: flex;
		font-size: 12px;
		white-space: nowrap;

		& > div {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		div + div {
			margin-left: 10px;
		}

		a {
			color: var(--text-light);

			&:hover {
				color: var(--secondary);
			}
		}

		svg * {
			fill: var(--secondary);
		}
	}
}

.user-card-confirm {
	max-width: 300px;
}
