.notifications {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: fixed;
	top: 75px;
	right: 75px;
	z-index: 5000;
	font-size: 14px;
	align-items: flex-end;

	.notification {
		padding: 12px 16px;
		border-radius: 4px;
		color: var(--white);
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 20px;


		.notification-message-text {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 5px;
		}


		&-animation-basic {
			@keyframes notification {

				0% {
					opacity: 0;
				}

				10% {
					opacity: 1;
				}

				90% {
					opacity: 1;
				}

				100% {
					opacity: 0;
				}
			}

			opacity: 0;
			animation: notification 17s !important;

		}

		&-animation-no-fade-out {
			@keyframes noFadeOutNotification {
				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}

			animation: noFadeOutNotification 1s;
		}

		&>p {
			margin: 0;
		}

		&-success {
			background-color: var(--primary);
		}

		&-error {
			background-color: var(--error);
		}

		&-danger {
			background-color: var(--danger);
		}

		&-warning {
			background-color: var(--warning);
		}


		&-info {
			background-color: var(--info);
		}

		.controls {
			display: flex;
			gap: 12px;

			.open-button {
				border-color: #fff;
			}
		}

	}
}