.report-order-popup-edit {
	.columns {
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		max-height: 500px;

		.checkbox {
			color: var(--text);
			padding: 10px;
			transition: background var(--transition-timing);
			cursor: pointer;

			&:hover {
				border-radius: var(--border-radius);
				background-color: var(--button-hover-default-bg);
			}
		}
	}

	.actions {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.text-actions,
		.button-actions {
			display: flex;
			gap: 20px;
		}

		.text-actions {
			span {
				transition: color var(--transition-timing);
				cursor: pointer;

				&:hover {
					color: var(--secondary);
				}
			}
		}
	}
}

.popup-actions {
	display: flex;
	gap: 10px;
}
