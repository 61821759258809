.wrapper-engines-table{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2px;

  .engine-item-table{
    width: calc((100% - 2px) / 2);
    line-height: 1em;
    text-align: center;
    background: var(--white);
    border-radius: 3px;
    padding: 2px 0px;
    font-size: 12px;
    min-height: 16px;
  }
}