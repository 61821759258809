.logbook {
	display: flex;
	flex-direction: column;
	gap: 30px;
	height: 100%;

	.FileField {
		overflow: unset;
	}

	>* {
		display: flex;
		width: 100%;
		gap: 50px;

		.flex {
			display: flex;
			flex-direction: column;

			span {
				overflow-wrap: break-word;
			}
		}
	}

	.FileField{
		width: max-content;
	}

	.sp-table{
		tbody{
			td{
				padding: 5px var(--table-cell-padding) !important;
				height: auto;
				.wrapper-controls{
					display: flex;
					gap: 16px;
					align-items: center;
					justify-content: flex-end;
					.btn-table-logbook{
						border: none;
						background: transparent;
						box-shadow: none;
						height: auto;
						width: auto;
						min-height: unset;
						min-width: unset;
						line-height: 1em;
					}
				}
			}
		}
	}

	.ui-pager{
		gap: 5px;
	}

	.wrapper-progress-archiving{
		display: flex;
		gap: 5px;
		align-items: center;
		min-width: 60px;
		justify-content: flex-end;
	}
}