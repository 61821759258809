.plane-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon-container {
        position: relative;
        width: 35px;
        height: 35px;
        .engine-icon {
            position: absolute;
            inset: 0;
        }
    }

    .warning-sign {
        font-size: 20px;
        color: #FFC048;
    }
}