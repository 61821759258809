.sidebar {
	height: 100vh;
	background: var(--slate-800);
	display: flex;
	flex-direction: column;
	width: var(--sidebar-width);
	color: var(--slate-400);
	font-size: 1rem;

	.logo {
		width: 100%;
		height: var(--header-height);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: var(--sidebar-left-padding);
		border-bottom: 1px solid var(--slate-700);

		a {
			display: flex;
			gap: 7px;
			height: 100%;
			align-items: center;

			svg {
				width: 34px;
				height: 20px;

				&.logo-text {
					width: 94px;

					*[fill] {
						fill: var(--slate-400);
					}
				}
			}

			.logo-system{
				max-height: calc(100% - 10px);
			}
		}
	}
}

.mobile-overlay {
	display: none;
}

@media (max-width: 1600px) {
	.sidebar {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		height: auto;
		z-index: 1000;
		transform: translate3d(calc(-1 * var(--sidebar-width)), 0, 0);
		transition: all var(--transition-timing) ease-out;

		&.active {
			transform: none;
		}
	}

	.mobile-overlay {
		z-index: 900;
		display: none;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(#000, 0.4);
		opacity: 0;
		transition: all var(--transition-timing) ease-out;

		&.active {
			display: block;
			opacity: 1;
		}
	}
}
