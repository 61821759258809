.auth {
	flex-basis: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 15px;

	h3 {
		font-size: 18px;
		margin-bottom: 24px;
	}

	.copyright {
		margin-top: 35px;
		color: var(--text-lighter);
	}
}

.auth-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
	width: 370px;
	padding: 15px;
	overflow-y: auto;

	.field {
		width: 100%;
		margin-bottom: 16px;

		&.last {
			margin-bottom: 40px;
		}

		label {
			color: var(--text-light);
			display: block;
			margin-bottom: 9px;
		}
		input {
			display: block;
			width: 100%;
		}
	}

	.submit {
		display: flex;
		gap: 20px;
	}
	.passwordChanged {
		font-weight: bold;
		color: var(--primary);
		margin-bottom: 20px;
	}
}

.captcha {
	display: flex;
	gap: 20px;
	flex-direction: row-reverse;
	align-items: center;
	margin-bottom: 15px;

	.reload {
		cursor: pointer;
	}
}
