.trend-report-table {
	margin-top: 20px;

	.empty-column {
		padding: 0;
		width: 10px;
	}

	thead tr th .column-header .column-title {
		margin-right: 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		min-height: 100px;
	}
}

.parameter-title {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 80px;
}

.trend-report-param-title-wrapper {
	min-height: 110px;

	.column-title-parameter-name {
		display: flex;
		justify-content: center;
		text-align: center;
		font-size: 12px;
	}
}

.column-title-parameter-diapason {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.engine1 {
	.number-value {
		color: #5470c6 !important;
	}
	color: #5470c6 !important;
}

.engine2 {
	.number-value {
		color: #95ce7a !important;
	}
	color: #95ce7a !important;
}

.column-title-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.zoom-icon {
	height: 16px;
	width: 16px;
	margin-left: 10px;
}

.zoom-100 {
	margin-right: 10px;
	font-weight: 600;
}

.column-title-button-zoom {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	background-color: #ebf0f3;
	color: #ffffff;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: box-shadow 0.3s ease;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Стиль кнопки при наведении */
.column-title-button-zoom:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Добавление тени при наведении */
}

/* Стиль кнопки при нажатии */
.column-title-button-zoom-pressed {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	background-color: #ebf0f3;
	color: #ffffff;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.sp-tabs-content:has(.wrapper-trend-tab){
	display: flex;
	flex-direction: column;
}
.wrapper-trend-tab{
	flex-grow: 1;
	overflow: auto;

	.wrapper-heads, .wrapper-contents{
		display: flex;
		gap: 10px;

		& > div{
			min-width: 170px;
			max-width: 170px;
		}
	}
	.wrapper-heads{
		padding-top: 30px;
		position: sticky;
		top: 0;
		z-index: 1;
		background: var(--white);
		width: max-content;
	}
	.wrapper-contents{
		padding-bottom: 30px;
	}

	.wrapper-filter{
		max-width: 520px;

		.second-row{
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 10px;
			width: 100%;

			.label-field{
				margin: 0px;

				.sp-dropdown{
					width: 100%;
					max-width: unset;
					min-width: unset;
				}

			}

			.engines-conf{
				flex-grow: 1;
			}

			.smoothed{
				.Switch{
					margin-left: 5px;
				}
			}
		}
	}
}

.Popover{
	z-index: 2;
}

@media(max-width: 520px){
	.wrapper-trend-tab{
		.wrapper-filter{
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			padding-right: 16px;

			& > * {
				margin: 0px !important;
			}

			& > .button-group{
				display: flex;
				flex-wrap: nowrap;
			}

			.second-row{
				.engines-conf{
					width: 100%;
				}
			}
		}
	}
}
