.avatar-image-loader {
	display: flex;

	.avatar-image-preview {
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer;
		position: relative;

		&:hover {
			&::after {
				content: 'Изменить';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: white;
				z-index: 10;
			}

			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 160px;
			height: 160px;
			object-fit: cover;
			transition: all var(--transition-timing);
		}
	}
}

.sp-portal.sp-popup {
	.avatar-image-popup {
		.sp-popup-content {
			border-radius: var(--border-radius);
			height: 520px;
			width: 480px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			div {
				h2 {
					color: var(--text);
					margin-bottom: 20px;
				}

				.buttons {
					margin-top: 10px;
					display: flex;
					gap: 20px;
					justify-content: space-between;

					input {
						display: none;
					}

					label > button:active {
						pointer-events: none;
					}
				}
			}
		}
	}
}
