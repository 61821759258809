.collapse {
	&-label {
		display: flex;
		justify-content: space-between;
		padding-right: 20px;
	}

	&_select-options {
		display: flex;
		gap: 30px;
	}
}

.wrapper-collapse-menu{

	.Collapse-Label{
		position: relative;

		.Collapse-Side{

			line-height: 1em;

			.collapse_select-options{
				position: absolute;
				right: 24px;
				min-height: 100%;
				top: 0px;

				& > div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
}
