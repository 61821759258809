.basic-list-toolbar {
	padding-bottom: 10px;
	border-bottom: var(--border-width) solid var(--border-color);
}

.basic-list {
	height: 100%;
	display: flex;
	flex-direction: column;

	#table {
		height: 100%;
		flex-grow: 1;
		position: relative;
		z-index: 1;
	}
}

@media (max-width: 600px) {
	.model-list {
		.table-with-pager {
			.ui-pager {
				flex-direction: column;
				margin-bottom: 100px;

				.pages {
					margin: 0;
					margin-bottom: 30px;
				}

				.items-per-page {
					margin-bottom: 20px;
				}
			}
		}
	}
}
