.logbook {
    .logbook-toolbar {
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;
            gap: 20px;
            align-items: flex-end;

        }

        .right {
            margin-top: 24px;
        }

        .date-field, .status-field {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}