.settings-content{
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .wrapper-content-settings{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .wrapper-image-setting{
      max-width: 100%;
      position: relative;

      img{
        width: max-content;
        max-width: 100%;
      }

      .wrapper-delete-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
        opacity: 0;
        transition: all .3s;
        cursor: pointer;

        svg{
          color: var(--white);
        }
      }

      &:hover{
        .wrapper-delete-image{
          z-index: 1;
          opacity: 1;
        }
      }
    }

    .DragNDropField{
      min-height: auto;
    }

    .wrapper-btn-group{
      display: flex;

      button{
        margin: 0px;
        min-width: 60px;

        &:first-child{
          border-radius: 0.25em 0px 0px 0.25em;
        }

        &:last-child{
          border-radius: 0px 0.25em 0.25em 0px;
        }
      }
    }

    .item-setting-switch{
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}