.edit-transport-data-popup {
    .wrapper {
        display: flex;
        flex-direction: row;

        .left {
            width: 100%;
            min-width: 600px;
            .summary {
                display: flex;
                flex-direction: column;
                gap: 3px;
                margin-bottom: 5px;
                div > span {
                    font-size: 14px;
                }
                .label {
                    color: var(--primary);
                }
                .ui-checkbox {
                    margin-left: 10px;
                }
            }
            .period {
                color: #000;
                margin-left: 10px;
                font-weight: bold;
            }
            h2 {
                margin-bottom: 10px;
            }
            textarea {
                max-width: 100%;
                min-width: 100%;
                max-height: 300px;
                min-height: 100px;
            }
            .active {
                display: flex;
            }
        }
        .notify-logs-list {
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-right: 20px;
            }
            h2 {
                margin-top: 10px;
            }

            margin-left: 15px;

            min-width: 600px;
            margin-top: -20px;
        }
    }
}
