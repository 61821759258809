#pdf-export {
	display: none;
	font-size: 12;

	.pdf-content {
		display: grid;
		justify-content: space-between;
		gap: 20px;
		grid-template-columns: 1fr 1fr 1fr;
		padding-bottom: 20px;

		.block {
			display: flex;
			gap: 15px;
			align-items: center;

			img {
				height: 24px;
				width: 24px;
			}
		}

		.user {
			display: flex;
			justify-content: flex-end;
		}
	}
}
