.content-section.profile {
	padding: 20px;
	background-color: var(--main-bg);
	border-radius: var(--border-radius);
	margin-bottom: 0;

	header {
		margin: -20px;
		margin-bottom: 20px;
		border-radius: var(--border-radius) var(--border-radius) 0 0;
	}

	.avatar-image-loader {
		margin-bottom: 20px;
	}

	.ui-form {
		.sp-row {
			margin-bottom: 16px;
			gap: 30px;

			.sp-col {
				max-width: 50%;
				margin: 0px;

				.form-field {
					margin-bottom: 0;

					.password-field {
						position: relative;

						> button {
							position: absolute !important;
							right: 10px;
							background: transparent;
							box-shadow: none;
							top: 0;
							display: flex;
							justify-content: center;
							align-items: center;

							svg{
								display: block;
								color: var(--primary)
							}
						}
					}

					> small {
						color: var(--text-lighter);
					}
				}
			}
		}

		.image-input {
			.image-preview {
				a {
					img {
						position: relative;
						border-radius: 50%;
						transition: all var(--transition-timing);
					}
				}
			}

			&:hover {
				img {
					filter: brightness(0.5);
				}
			}
		}

		.custom-field-wrapper {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 15px;

			p:first-child {
				font-size: 12px;
				color: var(--form-label-color);
			}
		}
	}
}
