.acl-form {
	.subjects {
		.form-input {
			display: inline-block;
			width: 30%;
			margin-right: 20px;
			vertical-align: top;
		}
	}
}


.acl-table {
	tbody tr td {
		overflow: visible;
	}
}
