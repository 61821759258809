.sidebar {
	.menu {
		flex-grow: 1;
		overflow-y: auto;
		position: relative;
		scrollbar-gutter: stable;

		.submenu {
			margin-top: 13px;

			&.hidden {
				.submenu__title {
					svg {
						transform: rotate(180deg);
					}
				}
			}

			&__title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 10px 0 20px;
				text-transform: uppercase;
				user-select: none;
				margin-bottom: 13px;
				cursor: pointer;
			}

			&__items {
				&--item {
					position: relative;
					display: flex;
					height: 32px;
					line-height: normal;
					color: var(--slate-400);
					font-weight: 500;
					text-decoration: none;
					border-left: 3px solid transparent;
					transition: all var(--transition-timing);
					cursor: pointer;

					.icon {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-basis: 20px;
						flex-shrink: 0;
						text-align: center;
						margin-right: 10px;
						margin-left: 20px;

						svg {
							height: 16px;

							&.sidebar-react-icon {
								width: 20px;
								height: 16px;
							}

							*[stroke] {
								stroke: var(--slate-400);
							}

							*[fill] {
								fill: var(--slate-400);
							}
						}

						.no-icon {
							display: inline-block;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background: var(--text-light);
						}
					}

					.title {
						display: flex;
						min-width: 0px;
						gap: 15px;
						align-items: center;
						padding-right: 5px;
						position: relative;
						user-select: none;

						&.badge {
							.count {
								margin-right: 25px;
							}
						}
					}

					&:hover {
						border-color: var(--white);
						background-color: var(--slate-700);
					}

					&.active {
						border-color: var(--white);
						color: var(--white);

						.icon {
							color: var(--white);

							svg {
								*[stroke] {
									stroke: var(--white);
								}

								*[fill] {
									fill: var(--white);
								}
							}
						}
					}
				}
			}
		}
	}
}
