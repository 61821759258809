.content-section {
	background: var(--main-bg);
	padding: 15px;
	height: auto;
	border-radius: var(--border-radius);

	.ui-form .form-field label:first-child {
		font-size: 13px;
	}

	& > header:first-child {
		background: var(--secondary-bg);
		color: var(---text);
		margin: -15px -15px 15px -15px;
		font-size: 1rem;
		font-weight: 500;
		padding: 0 15px;
		height: 40px;
		display: flex;
		align-items: center;
		border-radius: var(--border-radius) var(--border-radius) 0 0;
	}
}
