.composite-toolbar {
	display: flex;
	align-items: center;
	gap: var(--toolbar-gap-row) var(--toolbar-gap-column);
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 20px;

	label {
		color: var(--text-light);
		display: block;
		margin-bottom: 8px;
	}

	.sp-trigger.sp-dropdown.record-select {
		width: 220px;
		min-width: 200px;
	}

	.sp-btn {
		height: var(--toolbar-height) !important;
	}

	.top {
		display: flex;
		gap: 20px;
		width: 100%;
		padding-bottom: 10px;
		border-bottom: var(--border-width) solid var(--border-color);
	}

	.right {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
	}

	.bottom {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 20px;
		width: 100%;
		align-items: flex-end;
	}

	.left {
		display: flex;
		gap: 20px;
		flex-wrap: wrap;
	}

	.toolbar-user {
		min-width: 200px;
	}

	&.toolbar-page {
		padding-bottom: var(--ui-toolbar-padding-bot);
		border-bottom: var(--border-width) solid var(--border-color);
	}

	.toolbar-count {
		color: var(--slate-400);

		.count {
			display: inline-block;
			min-width: 35px;
		}
	}

	.toolbar-create-btn {
		justify-content: space-between;
		align-items: center;
		padding: 0px 10px;
		min-width: 160px;
	}

	.toolbar-search {
		display: flex;
		align-items: center;
		border: var(--border-width) solid var(--border-color);
		height: var(--ui-filter-height);
		border-radius: 4px;
		background: var(--main-bg);
		padding: 0 10px;
		min-width: 250px;
		flex-basis: 300px;

		.icon {
			color: var(--primary);
		}

		input {
			padding: 0 10px 0 0;
			width: 100%;
			border: none;
			background: transparent;
		}
	}

	.members {
		display: flex;
		align-items: center;

		.title {
			margin-right: 10px;
			color: var(--text-lighter);
		}

		.members-avatars {
			.avatars {
				.member-avatar {
					.sp-avatar {
						display: flex;
						align-items: center;
						justify-content: center;

						.badge {
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							top: -6px;
						}
					}
				}
			}
		}
	}

	.members-filter {
		.trigger {
			height: var(--ui-filter-height);
			display: flex;
			align-items: center;
			padding: 0 10px;

			.clear {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media (max-width: 992px) {
	.composite-toolbar {
		.filters {
			width: 100%;
			justify-content: space-between;

			.toolbar-user,
			.list-select,
			.sort-select {
				order: 1;
				width: 30%;
			}

			.closed-filters {
				order: 2;
			}

			.icon-btn {
				order: 3;
			}
		}
	}
}

@media (max-width: 767px) {
	.composite-toolbar {
		margin-top: var(--margin-top-small-screen);
		flex-wrap: wrap;
		flex-basis: auto;

		.top {
			flex-direction: column;
			gap: 10px;

			.left {
				width: 100%;
			}
		}

		.toolbar-create-btn {
			width: 100% !important;
			margin-right: 0px;
		}

		.toolbar-search {
			flex: 1;
		}

		.toolbar-search,
		.toolbar-user {
			width: 100%;
		}

		.toolbar {
			&-user-select,
			&-project-select,
			&-list-select {
				width: 100%;

				.sp-trigger.sp-dropdown.record-select {
					width: 100%;
				}
			}
		}
	}
}
