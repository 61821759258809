.card {
    background: var(--white);
    border: 1px solid rgba(#000, 0.15);
    box-shadow: 1px 3px 10px rgba(#000, 0.05);
    border-radius: 5px;

    &-title {
        display: flex;
        align-items: center;
        border-bottom: 1.5px solid var(--light-grey);
        height: 48px;
        padding: 12px 16px;
        svg {
            margin-right: 12px;
        }
        h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
        }
    }
    &-content {
        padding: 16px;

        & > div:last-child {
            & > div > .form-field {
                margin-bottom: 0 !important;
            }
        }
    }
}
