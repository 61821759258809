.trend-scale {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	position: relative;

	.min-container {
		border-right: 2px solid red;
	}

	.max-container {
		width: 2px;
		height: 100%;
		background-color: red;
		position: absolute;
	}

	.max-container-def {
		border-left: 2px solid red;
	}

	.tag-container {
		height: 100%;
		width: 100%;
		position: relative;

		.tag {
			background-color: rgb(50, 50, 50);
			position: absolute;
			height: 100%;
			width: 2px;
			border-radius: 10px;
			transform: translateX(-50%);
		}
	}
}

.border-column {
	padding: 0 !important;

	.string-value {
		width: 100%;
		height: 100%;

		.sp-trigger.sp-popover {
			width: 100%;
			height: 100%;
		}
	}
}

.trend-report-wrapper {
	.sp-table tbody {
		border-spacing: 80px;
		tr {
			td {
				height: 20px;
			}

			&.border {
				> td {
					height: 1px;
				}
			}

			.border-column {
				&:not(:first-child) {
					padding: 0 !important;

					.string-value {
						width: 100%;
						height: 100%;

						.sp-trigger.sp-popover {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}

	.column-header {
		.column-title {
			width: 100%;
			> * {
				width: 100%;
			}
		}
	}
}
