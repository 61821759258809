.icon-btn {
    margin-left: unset !important;
    border: 2px solid transparent !important;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    &.plus-btn {
        width: 40px;
    }

    &.filter-btn,
    &.add-btn-secondary,
    &.add-contragent-button,
    &.upload-btn,
    &.export-btn {
        background-color: #fff !important;

        span {
            color: var(--dark-grey);
            font-size: 14px;
        }

        &:hover,
        &.active {
            span {
                color: var(--primary);
            }

            svg {
                *[fill] {
                    fill: var(--primary);
                }

                *[stroke] {
                    stroke: var(--primary);
                }
            }
        }

        &.active {
            border-color: var(--primary) !important;
        }

        svg {
            font-size: 16px;

            *[fill] {
                fill: #b8b8b8;
            }
        }
    }

    &.clean-btn {
        background-color: unset !important;
        color: var(--primary) !important;

        &:hover {
            transform: unset !important;
            box-shadow: unset !important;
        }
    }

    &.cancel-btn {
        background-color: #fff !important;
        color: var(--dark-grey) !important;
    }

    .close-btn {
        background-color: unset !important;
        width: 14px !important;
        height: 14px !important;
        padding: unset !important;
    }

    &.add-btn-secondary {
        svg {
            width: 16px !important;
            height: 16px !important;

            [fill] {
                fill: var(--grey);
            }
        }
    }

    &.add-contragent-button {
        svg {
            width: 25px !important;
            height: 25px !important;

            [fill] {
                fill: var(--grey);
            }
        }
    }
}

.delete-button {
    cursor: pointer;
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease-out;

    svg {
        height: 16px;
    }

    &:hover {
        transform: translate(0, -1px);

        svg {
            *[stroke] {
                stroke: var(--danger);
            }

            *[fill] {
                fill: var(--danger);
            }
        }
    }

    span {
        margin-left: 8px;
        color: var(--dark-grey) !important;
    }
}

.popconfirm-fa-times {
    height: 18px;
    width: 18px;

    *[fill] {
        fill: var(--grey);
    }

    cursor: pointer;

    &:hover {
        *[fill] {
            fill: var(--danger);
        }
    }
}

.add-row {
    height: 40px;
    padding: 6px 0;
    background: var(--super-light-grey);
    text-align: center;

    .sp-dropdown-items {
        .active {
            background-color: var(--primary);
        }
    }

    .add-btn {
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(#000, 0.05);
        transition: all 150ms;

        &:hover {
            transform: scale(1.1, 1.1);
            box-shadow: 0 2px 3px rgba(#000, 0.15);
        }
    }

    &.disabled {
        pointer-events: none;
        filter: saturate(0);
    }
}