.chart-settings {

  .icon {
    cursor: pointer;
    font-size: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--color-typo-primary)
  }
}

.popup-chart-settings {
  padding: 8px;

  .content-settings {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .head {
      padding-bottom: 4px;
      border-bottom: 1px solid #cccccc;
    }

    .item-setting {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > p {
        font-size: 14px;
      }
      .content{
        display: flex;
        flex-direction: column;
        gap: 4px;

        .color-item{
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .color-picker{
          width: 50px;
          height: 25px;
        }
      }
    }
  }
}