.graphic-report {
	width: 100%;
	display: flex;
	height: 100%;
	gap: 16px;

	.Text {
		padding-left: 25px;
	}

	.left-side {
		display: flex;
		flex-direction: column;
		position: relative;
		flex-basis: 30%;
		padding-right: 16px;

		&.loading {
			opacity: 0.3;
			pointer-events: none;
		}

		.Loader {
			position: absolute;
			top: 50%;
			left: 50%;
		}

		.second-row{
			margin-bottom: 10px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 10px;

			.label-field{
				flex-grow: 1;
				margin: 0px;

				.sp-dropdown{
					width: 100%;
					max-width: unset;
					min-width: unset;
				}

				.wrapper-slide-input{
					display: flex;
					align-items: center;

					.number-input{
						max-width: 40px;
						flex-shrink: 0;
						text-align: center;
					}
					.sp-slide-input{
						flex-grow: 1;
						padding: 0px 12px;
						height: max-content;
					}
				}
			}

			.engines-conf{
				flex-grow: 0;
				min-width: 200px;
				max-width: 200px;

			}

			.smoothed{
				.Switch{
					margin-left: 5px;
				}
			}

			.delta{
				max-width: 200px;
			}
		}

		.aircraftSelect {
			margin-right: 10px;
		}

		.firstRow {
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.left-first-row{
				flex-grow: 1;
				display: flex;
				flex-wrap: wrap;
				gap: 16px;

				& > *{
					margin: 0px;
				}

				& > .button-group{
					display: flex;
					flex-wrap: nowrap;
				}

			}
		}

		.phaseSelect {
			margin-left: 15px;
		}
	}

	.right-side{
		flex-basis: 70%;
	}

	> * {
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 0px;
		scrollbar-gutter: stable;
	}

	.params {
		margin-top: 10px;
		.Collapse {
			.Collapse-Content {
				.collapsed-menu {
					display: flex;
					flex-direction: column;
					gap: 15px;
					padding-left: 5px;
				}
			}
		}
	}
}

.item-conf{
	display: flex;
	align-items: center;
	gap: 10px;

	span{
		font-size: 9px;
	}
}


@media(max-width: 1024px){
	.graphic-report{
		flex-wrap: wrap;
		.left-side, .right-side{
			flex-basis: 100%;
			padding-right: 0px;
		}
	}
}

@media(max-width: 520px){
	.graphic-report{

		.left-side{
			
			.firstRow{
				align-items: flex-start;
			}
		}
	}
}
