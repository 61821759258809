.engine {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 400px;

	.engine-data {
		display: flex;
		gap: 20px;
		justify-content: space-between;
	}

	.engine-form {
	}
}
