.input-password {
	font-size: 13px;

	.password-field {
		position: relative;

		> .icon-btn {
			position: absolute !important;
			right: -100;
			bottom: 0;
		}

		> button {
			position: absolute !important;
			right: 0;
			bottom: 0;
		}
	}
}

.password-with-validate {
	.wrapper-password-hint {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-top: 10px;

		.item-hint-password {
			display: flex;
			gap: 4px;
			align-items: center;
			font-size: 12px;
			color: var(--success);

			&.invalid {
				color: var(--error);
			}
		}
	}

	.field {
		margin: 0px;
	}
}

.input-password > label:first-child{
	display: block;
	margin-bottom: 5px;
	color: #0af;
	color: var(--form-text-accent, #0af);
	font-weight: normal;
}
