.user-section {
	display: flex;
	gap: 10px;
	align-items: center;

	.profile-link {
		padding: 4px;
		transition-duration: var(--transition-timing);
		transition-property: background;
		border-radius: 20px;

		&:hover {
			background-color: var(--slate-500);
		}
	}

	a {
		text-decoration: none;
		color: var(--slate-800);

		&:hover {
			color: var(--header-link-hover);
		}
	}
}
