.dictionaries-page {
	height: calc(100% - 40px);

	.content {
		display: flex;
		gap: 20px;
		height: 100%;

		.model {
			width: 100%;
			overflow-y: auto;
			height: 100%;

			.fixed-page {
				flex: 1;
			}

			.sp-tabs {
				.sp-tabs-content {
					height: calc(100% - 65px);
				}
			}

			.geo-edit {
				margin-bottom: 15px;
				.geo-edit-map {
					margin-top: 5px;
					flex-basis: unset;
				}
			}

			.form-field {
				flex: 1;
				width: 100%;
				max-width: 1600px;

				&.disabled {
					> div {
						pointer-events: none;
					}
				}

				&.filed-with-hint{
					label{
						width: 100%;
						display: flex;
						gap: 5px;

						svg{
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
