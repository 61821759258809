.sp-btn {
	--medium-button-size-no-px: 36;
	--default-font-size: 14px;
	--slate-200: #e2e8f0;
	--primary-bg: var(--blue-500);
	--default-border: var(--slate-200);
	--default-bg: var(--white);
	--danger-bg: #f44359;
	--primary-bg: var(--primary);

	height: 2.5714em;
	position: relative !important;
	font-size: var(--default-font-size);

	&.sp-btn-sm {
		--default-font-size: 12px;
		max-height: 28px;
		text-transform: unset;
		font-weight: unset;
	}

	&.sp-btn-lg {
		--default-font-size: 16px;
		max-height: 44px;
		text-transform: unset;
		font-weight: unset;
	}

	&:hover {
		box-shadow: none !important;
		filter: none !important;
	}

	&.sp-btn-primary {
		&:hover {
			background: color-mix(in srgb, var(--primary-bg) 85%, black);
		}

		&:active {
			&:hover {
				background-color: color-mix(in srgb, var(--primary-bg) 85%, black);
			}
		}
	}

	&.sp-btn-default {
		&:hover {
			background: color-mix(in srgb, var(--default-bg) 95%, black);
		}

		&:active {
			&:hover {
				background: color-mix(in srgb, var(--default-bg) 95%, black);
			}
		}
	}

	&.sp-btn-danger {
		&:hover {
			background: color-mix(in srgb, var(--danger-bg) 85%, black);
		}

		&:active {
			&:hover {
				background: color-mix(in srgb, var(--danger-bg) 85%, black);
			}
		}
	}

	svg {
		height: 18px;
		margin-top: 0;

		*[fill] {
			transition: all 150ms;
		}
	}

	span + * {
		margin-left: 0;
	}
}
