.TextField_size_m {
	--input-height: 36px;
}

.sp-date-picker {
	input:hover {
		border: 1px solid var(--date-picker-border-color-hover) !important;
	}
	input:focus {
		border: 1px solid var(--date-picker-border-color-focus) !important;
	}
}